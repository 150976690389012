import React from 'react';

export default function BenefitCta({ heading, subheading }) {
  return (
    <div className="bg-secondary">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        {heading}
        <p className="mt-4 text-lg leading-6 text-gray-300">{subheading}</p>
        <a
          href="https://app.homigo.nl/signup"
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-yellow-500 sm:w-auto"
        >
          Probeer een maand gratis
        </a>
      </div>
    </div>
  );
}
